import React, {useEffect} from "react";
import "./getInTouch.css";

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. We will contact you soon.
    </p>
  );
};

const PopupForm = ({ showPopup, closePopup, sendEmail, result }) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const popupContent = document.querySelector(".popup-content");
      if (popupContent && !popupContent.contains(event.target)) {
        closePopup();
      }
    };

    if (showPopup) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showPopup, closePopup]);

  if (!showPopup) return null;

  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-btn" onClick={closePopup}>
          &times;
        </button>
        <form onSubmit={sendEmail}>
          <div className="form-group">
            <input type="text" name="name" placeholder="Full name" required />
          </div>

          <div className="form-group">
            <input type="email" name="email" placeholder="Email" required />
          </div>

          <div className="form-group">
            <input type="text" name="phone" placeholder="Phone Number" />
          </div>

          <div className="form-group">
            <input type="text" name="subject" placeholder="Subject" />
          </div>

          <div className="form-group">
            <textarea
              name="message"
              placeholder="Your Message"
              required
            ></textarea>
          </div>

          <div className="form-group">
            <button className="btn-default btn-large">Submit Now</button>
          </div>
          <div className="form-group">{result ? <Result /> : null}</div>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
