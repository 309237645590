import React, { useEffect, useState } from "react";
import HomeDefault from "./pages/HomeDefault";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import AboutUs from "./pages/AboutUs";
import WebDevelopmentQuote from "./elements/webDevelopment/webDevelopmentForm";
import RemoteSupport from "./pages/RemoteSupport";
import DefaultPage from "./pages/DefaultPage";
import DefaultService from "./pages/DefaultService";
import Contact from "./elements/contact/Contact";
import { base_url } from "./utils/base_url";
import "./assets/scss/style.scss";
import "leaflet/dist/leaflet.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    const fetchDataService = async () => {
      try {
        const response = await fetch(`${base_url}/api/services?populate=*`);
        const ServiceData = await response.json();
        setServiceData(ServiceData);
      } catch (error) {
      }
    };
    fetchDataService();
  }, []);

  return (
    <>
      <Router>
        <PageScrollTop>
        <ToastContainer />
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL + "/"}`}
              exact
              component={HomeDefault}
            />

            <Route
              path={`${process.env.PUBLIC_URL + "/about"}`}
              exact
              component={AboutUs}
            /> 

            <Route
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              exact
              component={Contact}
            /> 
             <Route
              path={`${process.env.PUBLIC_URL + "/remote-support"}`}
              exact
              component={RemoteSupport}
            />
          
            {serviceData?.data?.map((page) => (
              <Route
                key={page.id}
                path={`/Service/${page?.attributes?.slug}`}
                render={(props) => {
                  return (
                    <DefaultService pageData={page?.attributes} {...props} />
                  )
                }}
              />
            ))}
          </Switch>
        </PageScrollTop>
      </Router>
    </>
  );
};

export default App;
